import type { DialogState } from "ariakit";
import {
  cloneElement,
  createContext,
  isValidElement,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { F } from "ts-toolbelt";

import { atlasArrowLeft } from "../../icons/atlas";
import { RequiredChildren, useScheduleFocus } from "../__utils/react";
import { Button } from "../button/Button";
import type {
  AtlasDialogContentOptions,
  AtlasDialogContentProps,
  AtlasViewRendererProps,
} from "./types";

// config
// ------

const BACK_BUTTON_ICON = atlasArrowLeft;

// types
// -----

type DialogViewElement = ReactElement;
type DialogViewRenderer<K extends string> = (
  props: AtlasViewRendererProps<K>
) => DialogViewElement;
type DialogViewConfig<K extends string> = {
  backTarget?: K;
  view: DialogViewElement | DialogViewRenderer<K>;
  size?: NonNullable<AtlasDialogContentOptions["size"]>;
  variant?: NonNullable<AtlasDialogContentOptions["variant"]>;
};
type DialogViews<K extends string> = Record<K, DialogViewConfig<F.NoInfer<K>>>;
type UseDialogViewsOptions<K extends string> = {
  initialView?: K;
};

// dialog views context
// --------------------

export type DialogViewsContextValue =
  | { headerBackAction?: ReactElement }
  | undefined;

const DialogViewsContext = createContext<DialogViewsContextValue>(undefined);

export const DialogViewsContextProvider = DialogViewsContext.Provider;

export function useDialogViewsContext() {
  const value = useContext(DialogViewsContext);
  return useMemo(() => value ?? {}, [value]);
}

// integration with dialog content
// -------------------------------

export type DialogViewsIntegrationPassedProps = {
  contextValue?: { headerBackAction: JSX.Element | undefined };
  setCloseDialog?: (fn: () => void) => void;
  resetView?: () => void;
};

export type DialogViewsIntegrationProps = {
  props?: DialogViewsIntegrationPassedProps;
  dialog: DialogState;
} & RequiredChildren;

export function DialogViewsIntegration({
  props: { setCloseDialog, resetView, contextValue } = {},
  dialog,
  children,
}: DialogViewsIntegrationProps) {
  // register close dialog action
  const { setOpen, mounted } = dialog;
  useEffect(() => {
    setCloseDialog?.(() => () => setOpen(false));
  }, [setCloseDialog, setOpen]);

  // reset the view when the dialog is unmounted
  useEffect(() => {
    if (!mounted) resetView?.();
  }, [resetView, mounted]);

  return (
    <DialogViewsContextProvider value={contextValue as DialogViewsContextValue}>
      {children}
    </DialogViewsContextProvider>
  );
}

// use dialog views
// ----------------

function dummyCloseDialog() {
  throw new Error("Dialog close handle hasn't been set");
}

/**
 * Manages a set of views for a dialog.
 *
 * Accepts an object that maps view keys to configuration objects. Each config
 * accepts a `view` property that should contain a dialog view, or a render function
 * that returns it.
 *
 * Render functions receive a few utility functions like `setView`, `goBack`, or
 * `closeDialog`, that can be used in the view, e.g. to create a close button.
 *
 * Another property is `backTarget`, which is optional and indicated the view to
 * go back to when the user navigates to the previous view from a nested view.
 *
 * To wire it into a `Dialog`, spread the returned `dialogContentProps` object into
 * `Dialog.Content`.
 *
 * See Storybook for more detailed examples.
 *
 * @example
 * const { dialogContentProps } = useDialogViews({
 *   "top-view": {
 *     view: <Dialog.View>...</Dialog.View>,
 *   },
 *   "nested-view": {
 *     backTarget: "top-view",
 *     view: ({ goBack }) => <Dialog.View>...</Dialog.View>,
 *   },
 * )}
 *
 * return (
 *   <Dialog.Root>
 *     <Dialog.Content {...dialogContentProps} />
 *   </Dialog.Root>
 * )
 */
export function useDialogViews<K extends string>(
  views: DialogViews<K>,
  {
    initialView = Object.keys(views)[0] as K,
  }: UseDialogViewsOptions<F.NoInfer<K>> = {}
) {
  const [viewKey, setViewKey] = useState<K>(initialView);
  const { view, backTarget, size, variant } = views[viewKey];

  // dialog close method
  const [closeDialog, setCloseDialog] = useState(() => dummyCloseDialog);

  // initial focus
  const initialFocusRef = useRef<HTMLElement>(null);
  const scheduleInitialFocus = useScheduleFocus(initialFocusRef);
  const focusOnViewChange = useCallback(() => {
    // TODO: handle case where initialFocusRef is not set (focus first focusable element)
    queueMicrotask(scheduleInitialFocus);
  }, [scheduleInitialFocus]);

  // create methods
  const setView = useCallback(
    (key: K) => {
      setViewKey(key);
      focusOnViewChange();
    },
    [focusOnViewChange]
  );
  const goBack = useCallback(() => {
    if (backTarget) setView(backTarget);
    else throw new Error("No back target defined for the current dialog view");
  }, [backTarget, setView]);
  const resetView = useCallback(
    () => setView(initialView),
    [initialView, setView]
  );
  const methods: Omit<AtlasViewRendererProps<K>, "initialFocusRef"> = useMemo(
    () => ({
      goBack,
      resetView,
      setView,
      closeDialog,
    }),
    [closeDialog, goBack, resetView, setView]
  );

  // create renderer props
  const viewRendererProps = useMemo(
    () => ({ ...methods, initialFocusRef }),
    [initialFocusRef, methods]
  );

  // create view element
  const viewElement = useMemo(() => {
    if (isValidElement(view)) {
      return view;
    }
    if (typeof view === "function") {
      // Here's the type guard
      return view(viewRendererProps);
    }
    throw new Error("Invalid view provided");
  }, [view, viewRendererProps]);

  // create header back action if back target is defined
  const headerBackAction = useMemo(
    () =>
      backTarget ? (
        <Button
          icon={BACK_BUTTON_ICON}
          className="ml-[-.375rem]"
          onClick={() => setView(backTarget)}
        />
      ) : undefined,
    [backTarget, setView]
  );

  // create the children by cloning the view element
  const children = useMemo(
    // pass a key to prevent React reconciliation
    () => cloneElement(viewElement, { key: viewKey }),
    [viewElement, viewKey]
  );

  // create the context value for dialog views
  const contextValue = useMemo(
    () => ({ headerBackAction }),
    [headerBackAction]
  );

  // create the private props that are passed down to dialog content
  const dialogContentIntegrationProps = useMemo(
    (): DialogViewsIntegrationPassedProps => ({
      contextValue,
      setCloseDialog,
      resetView,
    }),
    [contextValue, resetView]
  );

  // create the dialog content props
  const dialogContentProps: AtlasDialogContentProps = useMemo(
    () => ({
      children,
      initialFocusRef,
      __dialogViewsIntegration: dialogContentIntegrationProps,
      ...(size ? { size } : undefined),
      ...(variant ? { variant } : undefined),
    }),
    [children, size, variant, dialogContentIntegrationProps]
  );

  return { dialogContentProps, ...methods, viewKey };
}
