import * as RadixTooltip from "@radix-ui/react-tooltip";
import {
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  isValidElement,
  ReactNode,
  useMemo,
} from "react";

import { getTooltipConfig } from "../__utils/__deprecated";
import { createComponentUtils } from "../__utils/atlas";
import { mergePropsIntoSingleChild } from "../__utils/react";
import { AtlasTooltipProps } from "./types";

// config
// ------

const COMPONENT_NAME = "Tooltip";
const DEFAULT_PROPS = {
  side: "top",
} as const;

const DELAY = 700;

const { el } = createComponentUtils(COMPONENT_NAME);

// trigger
// -------

type TooltipTriggerProps = {
  children?: ReactNode;
  type?: unknown;
  // eslint-disable-next-line camelcase
  UNSAFE_isFocusDisabled?: boolean;
  "data-state"?: unknown;
};

function TooltipTriggerComponent(
  {
    children,
    type,
    UNSAFE_isFocusDisabled: isFocusDisabled,
    "data-state": _,
    ...props
  }: TooltipTriggerProps,
  ref: ForwardedRef<unknown>
) {
  const focusProps: HTMLAttributes<HTMLElement> = {};
  if (!isFocusDisabled) focusProps.tabIndex = 0;

  return mergePropsIntoSingleChild({ ...props, ...focusProps, ref }, children);
}

const TooltipTrigger = forwardRef<unknown, TooltipTriggerProps>(
  TooltipTriggerComponent
);

// component
// ---------

/**
 * A popup that displays information related to an element when the element
 * receives keyboard focus or the mouse hovers over it.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Tooltip = forwardRef<any, AtlasTooltipProps>(
  (
    {
      side = DEFAULT_PROPS.side,
      children,
      isInstant,
      content,
      UNSAFE_isFocusDisabled: isFocusDisabledProp,
      UNSAFE_mode: mode,
      ...htmlProps
    },
    ref
  ) => {
    const tooltipConfig = useMemo(() => {
      if (!isValidElement(children)) return null;
      return getTooltipConfig(children);
    }, [children]);

    const isSlot = (mode ?? tooltipConfig?.mode) === "slot";
    const isFocusDisabled =
      isFocusDisabledProp || tooltipConfig?.isFocusDisabled;

    return (
      // @ts-expect-error - Radix is not typed correctly in React 18
      <RadixTooltip.Root delayDuration={isInstant ? 0 : DELAY}>
        <RadixTooltip.Trigger asChild>
          <TooltipTrigger
            UNSAFE_isFocusDisabled={isFocusDisabled}
            {...htmlProps}
            ref={ref}
          >
            {isSlot ? (
              children
            ) : (
              <span
                className={el`wrapper`}
                style={{
                  appearance: "button",
                  MozAppearance: "button",
                  WebkitAppearance: "button",
                }}
              >
                {children}
              </span>
            )}
          </TooltipTrigger>
        </RadixTooltip.Trigger>
        {content && (
          <RadixTooltip.Content
            className={el`content`}
            sideOffset={8}
            side={side}
          >
            <RadixTooltip.Arrow
              className={el`arrow`}
              width={10}
              height={6}
              offset={4}
            />
            <p className="text-body-md">{content}</p>
          </RadixTooltip.Content>
        )}
      </RadixTooltip.Root>
    );
  }
);

export default Tooltip;
