import {
  Content,
  GroupLabel,
  Item,
  Root,
  SectionHeading,
  Separator,
  Trigger,
} from "./Menu";

export const Menu = {
  Root,
  Trigger,
  Content,
  Item,
  Separator,
  GroupLabel,
  SectionHeading,
};
export * from "./types";
