import { Menu, MenuButton, MenuState } from "ariakit";
import clsx from "clsx";
import { useMemo } from "react";

import {
  scrollWorkaroundCompositeProps,
  useCompositeScrollWorkaround,
} from "../__utils/__deprecated";
import { usePExtendedState } from "../__utils/atlas";
import { usePCollection } from "../__utils/collections";
import { CompositeListRenderer } from "../__utils/CompositeListRenderer";
import {
  createDefaultProps,
  mergePropsIntoSingleChild,
} from "../__utils/react";
import { FloatingCard } from "../floating-card/FloatingCard";
import { AtlasOptionItemProps } from "../option/types";
import {
  AtlasMenuContentComponent,
  AtlasMenuContentItem,
  AtlasMenuContentProps,
  AtlasMenuRootComponent,
  AtlasMenuRootProps,
  AtlasMenuTriggerComponent,
} from "./types";
import { useMenuState } from "./use-menu-state";
import {
  BaseListProps,
  createComponent,
  el,
  RootProvider,
  useMenuRenderers,
  useRootContext,
} from "./utils/shared";
import { useSearchableMenuContent } from "./utils/use-searchable-menu-content";

// root
// ----

/**
 * The root of the menu component. Expects the trigger and the content.
 *
 * @example
 * <Menu.Root>
 *   <Menu.Trigger>
 *     <Button>Open menu</Button>
 *   </Menu.Trigger>
 *   <Menu.Content items={items} />
 * </Menu.Root>
 */
export const Root = createComponent<AtlasMenuRootComponent>(
  ({ children, state, ...stateProps }: AtlasMenuRootProps) => {
    const menu = useMenuState(stateProps);

    const contextValue = useMemo(
      () => ({ menu: state ?? menu }),
      [menu, state]
    );

    return <RootProvider value={contextValue}>{children}</RootProvider>;
  },
  { forwardRef: false, treeName: "Root" }
);

// trigger
// -------

/**
 * The trigger of a menu. Must be a child of `<Menu.Root />`. Expects the
 * button to be used as trigger, passed as a single child.
 *
 * @example
 * <Menu.Trigger>
 *   <Button>Open menu</Button>
 * </Menu.Trigger>
 */
export const Trigger = createComponent<AtlasMenuTriggerComponent>(
  ({ children, ...p0 }) => {
    const [menu, props] = usePExtendedState(p0, useRootContext().menu);

    return (
      <MenuButton state={menu} {...props}>
        {(menuButtonProps) =>
          mergePropsIntoSingleChild(menuButtonProps, children)
        }
      </MenuButton>
    );
  },
  { treeName: "Trigger" }
);

// content
// -------

// virtual composite list options

const COMPOSITE_ITEM_TYPES: AtlasMenuContentItem["_type"][] = ["item"];

const OPTION_ITEM_SIZE: Record<
  NonNullable<AtlasOptionItemProps["size"]>,
  number
> = { compact: 32, default: 40, open: 44 };
const OPTION_SEPARATOR_SIZE = 17;
const OPTION_GROUP_LABEL_SIZE = 32;
const OPTION_SECTION_HEADING_SIZE = 32 + 8;

function estimateItemSize(item: AtlasMenuContentItem) {
  /* eslint-disable no-underscore-dangle */
  if (item._type === "item") return OPTION_ITEM_SIZE[item.size ?? "default"];
  if (item._type === "separator") return OPTION_SEPARATOR_SIZE;
  if (item._type === "groupLabel") return OPTION_GROUP_LABEL_SIZE;
  if (item._type === "sectionHeading") return OPTION_SECTION_HEADING_SIZE;
  /* eslint-enable no-underscore-dangle */
  throw new Error("Unknown item type");
}

function isDynamicallySizedItem(item: AtlasMenuContentItem) {
  /* eslint-disable no-underscore-dangle */
  if (
    item._type === "separator" ||
    item._type === "groupLabel" ||
    item._type === "sectionHeading"
  )
    return false;
  if (item._type === "item") return !(item.render || item.renderContent);
  /* eslint-enable no-underscore-dangle */
  throw new Error("Unknown item type");
}

const BASE_VIRTUAL_COMPOSITE_OPTIONS = {
  compositeItemTypes: COMPOSITE_ITEM_TYPES,
  estimateSize: estimateItemSize,
  isDynamicallySized: isDynamicallySizedItem,
};

// list (menu list)

type ListProps = BaseListProps & { menu: MenuState };

function List({ items, isVirtual, menu, defaultSize }: ListProps) {
  const renderers = useMenuRenderers({ defaultSize });

  return (
    <CompositeListRenderer
      className={el`list`}
      items={items}
      renderers={renderers}
      isVirtual={isVirtual}
      virtualCompositeOptions={{
        compositeState: menu,
        ...BASE_VIRTUAL_COMPOSITE_OPTIONS,
      }}
    />
  );
}

// content

const DEFAULT_CONTENT_PROPS = createDefaultProps<AtlasMenuContentProps>()({
  emptyText: "No items",
  noResultsText: "No results",
} as const);

/**
 * The content of a menu. Must be a child of `<Menu.Root />`. Receives its content
 * through the `items` prop (dynamic collection) or as children (static collection).
 *
 * @example
 * <Menu.Content items={items}>
 */
export const Content = createComponent<AtlasMenuContentComponent>(
  (p0) => {
    const [menu, p1] = usePExtendedState(p0, useRootContext().menu);
    const [items, p2] = usePCollection(p1, "useMenuItems");
    const [{ searchableHeader, searchableList, isSearchableNoResults }, p3] =
      useSearchableMenuContent(p2, {
        menu,
        items,
        virtualCompositeOptions: BASE_VIRTUAL_COMPOSITE_OPTIONS,
      });

    const {
      emptyText = DEFAULT_CONTENT_PROPS.emptyText,
      noResultsText = DEFAULT_CONTENT_PROPS.noResultsText,
      header,
      footer,
      // TODO: replace with a context-based system (like ButtonGroup)
      defaultSize,
      isLoading,
      isVirtual,
      sameWidth,
      ...props
    } = p3;

    // fix focus-related scroll issues on browsers
    // TODO: fix in Ariakit directly
    useCompositeScrollWorkaround(menu);

    // header
    if (menu.searchable && header)
      throw new Error("Headers cannot be rendered in searchable menus");
    const resolvedHeader = searchableHeader ?? header;

    // list
    const resolvedList = searchableList ?? (
      <List
        items={items}
        isVirtual={isVirtual}
        // TODO: replace with a context-based system (like ButtonGroup)
        defaultSize={defaultSize}
        menu={menu}
      />
    );

    // empty state
    const isEmpty = isSearchableNoResults || items.length === 0;
    const resolvedEmptyText = isSearchableNoResults ? noResultsText : emptyText;

    return menu.mounted ? (
      <Menu
        data-placement={menu.currentPlacement}
        state={menu}
        // if searchable, the composite in use is the combobox, so disable the menu composite
        composite={!menu.searchable}
        {...props}
        className={clsx(el`content`, props.className)}
        {...scrollWorkaroundCompositeProps}
      >
        {(menuProps) => (
          <FloatingCard
            {...menuProps}
            header={resolvedHeader}
            bodyProps={{ className: el`content-body`, role: "presentation" }}
            footer={footer}
            isLoading={isLoading}
            isEmpty={isEmpty}
            emptyText={resolvedEmptyText}
            sameWidth={sameWidth}
          >
            {resolvedList}
          </FloatingCard>
        )}
      </Menu>
    ) : null;
  },
  { treeName: "Content" }
);

export { GroupLabel, Item, SectionHeading, Separator } from "./utils/shared";
