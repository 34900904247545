/* eslint-disable import/prefer-default-export */
import {
  Combobox,
  ComboboxItem,
  ComboboxList,
  ComboboxListProps,
  ComboboxSeparator,
  ComboboxState,
} from "ariakit";
import match from "autosuggest-highlight/match";
import { useMemo } from "react";
import { useDebouncedValue, useDidUpdate } from "rooks";

import { atlasSearch } from "../../../icons/atlas";
import { scrollWorkaroundCompositeProps } from "../../__utils/__deprecated";
import { CollectionItemRenderers } from "../../__utils/collections";
import {
  CompositeListRenderer,
  VirtualCompositeOptions,
} from "../../__utils/CompositeListRenderer";
import { Icon } from "../../icon/Icon";
import { OptionSeparator } from "../../option/OptionSeparator";
import type {
  AtlasMenuContentItem,
  AtlasMenuContentProps,
  AtlasMenuItemComponent,
  AtlasMenuSeparatorComponent,
  AtlasMenuState,
} from "../types";
import { getItemProps } from "./item";
import { BaseListProps, createComponent, el, useMenuRenderers } from "./shared";

// config
// ------

const DEFAULT_SEARCH_DEBOUNCE_WAIT = 500;
const TMP_COMBOBOX_INPUT_ICON = atlasSearch;

// combobox item renderer
// ----------------------

const ComboboxItemRenderer = createComponent<AtlasMenuItemComponent>(
  (props) => (
    <ComboboxItem
      value={props.children}
      focusOnHover
      setValueOnClick={false}
      {...getItemProps(props)}
    />
  ),
  { treeName: "Item" }
);

// combobox separator renderer
// ---------------------------

const ComboboxSeparatorRenderer = createComponent<AtlasMenuSeparatorComponent>(
  (props) => (
    <ComboboxSeparator {...props}>
      {(separatorProps) => <OptionSeparator {...separatorProps} />}
    </ComboboxSeparator>
  ),
  { treeName: "Separator" }
);

// search list (combobox list)
// ---------------------------

type SearchListProps = BaseListProps & {
  combobox: ComboboxState;
  comboboxListProps?: Partial<ComboboxListProps>;
  virtualCompositeOptions: Omit<
    VirtualCompositeOptions<AtlasMenuContentItem>,
    "compositeState"
  >;
};

function SearchList({
  items,
  isVirtual,
  combobox,
  defaultSize,
  comboboxListProps,
  virtualCompositeOptions,
}: SearchListProps) {
  const comboboxRenderers: Partial<
    CollectionItemRenderers<AtlasMenuContentItem>
  > = useMemo(
    () => ({
      separator: (props) => <ComboboxSeparatorRenderer {...props} />,
      item: (props) => <ComboboxItemRenderer size={defaultSize} {...props} />,
    }),
    [defaultSize]
  );

  const renderers = useMenuRenderers({
    defaultSize,
    overrideRenderers: comboboxRenderers,
  });

  return (
    <ComboboxList
      className={el`combobox-list`}
      state={combobox}
      {...comboboxListProps}
    >
      <CompositeListRenderer
        className={el`list`}
        items={items}
        renderers={renderers}
        virtualCompositeOptions={{
          compositeState: combobox,
          ...virtualCompositeOptions,
        }}
        isVirtual={isVirtual}
      />
    </ComboboxList>
  );
}

// filter
// ------

function filterItems(
  value: string,
  items: AtlasMenuContentItem[]
): AtlasMenuContentItem[] {
  const result: AtlasMenuContentItem[] = [];

  items.forEach((item) => {
    // eslint-disable-next-line no-underscore-dangle
    if (item._type !== "item") return;
    const matches = match(item.children, value, { requireMatchAll: true });
    if (matches.length > 0) result.push({ ...item, highlights: matches });
  });

  return result;
}

// searchable menu content
// -----------------------

type UseSearchableMenuContentOptions = {
  menu: AtlasMenuState;
  items: AtlasMenuContentItem[];
  virtualCompositeOptions: Omit<
    VirtualCompositeOptions<AtlasMenuContentItem>,
    "compositeState"
  >;
  header?: React.ReactNode;
};

export function useSearchableMenuContent(
  p0: AtlasMenuContentProps,
  { menu, items, virtualCompositeOptions }: UseSearchableMenuContentOptions
) {
  // extract props
  const {
    searchDebounceWait = DEFAULT_SEARCH_DEBOUNCE_WAIT,
    isInstantSearch,
    isFastSelection,
    comboboxProps,
    comboboxListProps,
    ...props
  } = p0;

  const { isVirtual, defaultSize } = props;
  const { combobox } = menu;

  // reset combobox value when menu is closed
  if (menu.searchable && !menu.mounted && combobox.value) combobox.setValue("");

  // optionally debounce the search value
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebouncedValue(
    combobox.value,
    searchDebounceWait
  );

  // reset debounced value when the combobox is empty
  if (menu.searchable && !combobox.value && debouncedSearchValue)
    setDebouncedSearchValue("");

  // resolve search value
  const resolvedSearchValue = useMemo(
    () =>
      isInstantSearch || combobox.value === ""
        ? combobox.value
        : debouncedSearchValue,
    [combobox.value, debouncedSearchValue, isInstantSearch]
  );

  // filter items with search value
  const filteredItems = useMemo(
    () =>
      menu.searchable && resolvedSearchValue
        ? filterItems(resolvedSearchValue, items)
        : undefined,
    [menu.searchable, resolvedSearchValue, items]
  );

  // fast selection: ensure there's (almost) always a selection
  useDidUpdate(() => {
    if (
      !menu.searchable ||
      !isFastSelection ||
      !combobox.items.length ||
      combobox.activeId
    )
      return;
    combobox.move(combobox.first());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolvedSearchValue]);

  // create searchable menu header
  const searchableHeader = menu.searchable ? (
    // use a combobox in the header if it's searchable
    <>
      {props.header}
      <Combobox
        state={combobox}
        autoComplete="list"
        placeholder="Search"
        autoSelect
        {...comboboxProps}
        {...scrollWorkaroundCompositeProps}
      >
        {(comboboxInputProps) => (
          // TODO: replace with <TextField /> once refactored
          <div className={el`tmp-combobox-input`}>
            <Icon content={TMP_COMBOBOX_INPUT_ICON} />
            <input {...comboboxInputProps} />
          </div>
        )}
      </Combobox>
    </>
  ) : undefined;

  // render searchable list
  const searchableList = menu.searchable ? (
    <SearchList
      items={filteredItems ?? items}
      isVirtual={isVirtual}
      combobox={combobox}
      comboboxListProps={comboboxListProps}
      // TODO: replace with a context-based system (like ButtonGroup)
      defaultSize={defaultSize}
      virtualCompositeOptions={virtualCompositeOptions}
    />
  ) : undefined;

  const isSearchableNoResults =
    menu.searchable && (filteredItems ?? items).length === 0;

  return [
    { searchableHeader, searchableList, isSearchableNoResults },
    props,
  ] as const;
}
